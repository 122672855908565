<template>
    <div class="container page vouchers">
        <loading-spinner
            v-if="loading && !existData"
            class="fixed"
        />
        <div
            v-if="existData"
            class="row"
        >
            <div class="mtd-40 mb-4">
                <h4>{{ $t('page.voucher.heading') }}</h4>
                <p class="lg mt-3">
                    {{ vouchers['text_1'] }}
                </p>
            </div>
            <div class="cards d-flex">
                <div class="predefined-cards">
                    <div class="items d-flex">
                        <div
                            v-for="index in 4"
                            :key="index"
                            class="item"
                            :class="{ selected: checkedIndex == index }"
                            @click="setValue(index)"
                        >
                            <label>
                                <div class="custom-checkbox">
                                    <input
                                        type="radio"
                                        name="compare"
                                        :checked="checkedIndex == index"
                                        :value="index"
                                    >
                                    <div class="checkmark">
                                        <check-icon />
                                    </div>
                                </div>
                                <div class="text">{{ vouchers['value_' + index] }} Ron</div>
                            </label>
                        </div>
                        <div
                            class="item custom"
                            :class="{ selected: checkedIndex == 5 }"
                            @click="setValue(5)"
                        >
                            <label>
                                <div class="custom-checkbox">
                                    <input
                                        type="radio"
                                        name="compare"
                                        value="5"
                                        :checked="checkedIndex == 5"
                                    >
                                    <div class="checkmark">
                                        <check-icon />
                                    </div>
                                </div>
                                <div class="text">
                                    {{ $t('page.voucher.anotherValueText') }}
                                </div>
                            </label>
                        </div>
                    </div>
                    <p class="lg mt-3">
                        {{ vouchers['text_2'] }}
                    </p>
                    <p class="lg mt-3">
                        {{ vouchers['text_3'] }}
                    </p>
                    <div class="actions mt-3">
                        <div
                            v-if="checkedIndex == 5"
                            class="custom-value"
                        >
                            <p>{{ $t('page.voucher.customizeButton') }}</p>
                            <div
                                class="custom-number"
                                :class="{
                                    error: errors.customValue || errors.checkedIndex,
                                }"
                            >
                                <input
                                    id="custom-value"
                                    type="number"
                                    name="custom-value"
                                    min="50"
                                    max="9999"
                                    maxlength="4"
                                    :value="customValue"
                                    @keyup="updateValue($event.target.value)"
                                    @change="updateValue($event.target.value)"
                                >
                                <div class="arrows">
                                    <chevron-up-icon @click="updateValue(customValue, 'inc')" />
                                    <chevron-down-icon
                                        @click="updateValue(customValue, 'decr')"
                                    />
                                </div>
                            </div>
                            <span
                                v-if="errors.customValue"
                                class="error-message"
                            >{{
                                errors.customValue
                            }}</span>
                            <span
                                v-if="errors.value"
                                class="error-message"
                            >{{
                                errors.value
                            }}</span>
                        </div>
                        <b-button
                            variant="primary"
                            class="fill"
                            :disabled="!checkedIndex || sending"
                            @click="addProductToCart()"
                        >
                            <shopping-cart-icon />{{ $t('page.product.addToCartButton') }}
                        </b-button>
                        <span
                            v-if="errors.checkedIndex"
                            class="error-message"
                        >{{
                            errors.checkedIndex
                        }}</span>
                    </div>
                </div>
                <div class="value-card">
                    <div class="image">
                        <div class="label">
                            <span>{{ formatedValue }}</span><span>Ron</span>
                        </div>
                        <img
                            :src="vouchers.image"
                            alt="voucher"
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CheckIcon from 'vue-feather-icons/icons/CheckIcon';
    import ChevronDownIcon from 'vue-feather-icons/icons/ChevronDownIcon';
    import ChevronUpIcon from 'vue-feather-icons/icons/ChevronUpIcon';
    import ShoppingCartIcon from 'vue-feather-icons/icons/ShoppingCartIcon';
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapState } from 'vuex';

    import UserAgentMixin from '@/mixins/userAgentMixin';
    import { generateToastHtml } from '@/services/generateToastHtml';
    import { inputErrors } from '@/services/inputErrors';

    import LoadingSpinner from '../components/loaders/LoadingSpinner';

    export default {
        mixins: [UserAgentMixin],
        name: 'Voucher',
        components: {
            CheckIcon,
            ShoppingCartIcon,
            ChevronDownIcon,
            ChevronUpIcon,
            LoadingSpinner,
        },
        data() {
            return {
                loading: false,
                sending: false,
                customValue: 50,
                formatedValue: 50,
                checkedIndex: null,
                name: this.$t('page.voucher.heading'),
                errors: {},
            };
        },
        computed: {
            ...mapState('voucher', ['vouchers']),
            ...mapState('cart', ['inputErrors']),
            lang() {
                return i18n.locale;
            },
            existData() {
                return Object.keys(this.vouchers).length > 0;
            },
        },
        methods: {
            ...mapActions('voucher', ['getVouchers']),
            ...mapActions('cart', ['addItem']),
            setValue(index) {
                this.checkedIndex = index;
                let value = this.customValue;
                if (index != 5) {
                    value = this.vouchers['value_' + index];
                } else {
                    value = 50;
                }
                this.customValue = value;
            },
            updateValue(value, direction = null) {
                if (direction) {
                    switch (direction) {
                    case 'inc':
                        if (this.customValue <= 9998) {
                            this.customValue = ++value;
                        }
                        break;
                    case 'decr':
                        if (this.customValue >= 51) {
                            this.customValue = --value;
                        }

                        break;

                    default:
                        break;
                    }
                } else {
                    this.customValue = value;
                }
                if (this.customValue >= 50 && this.customValue <= 9999) {
                    this.errors = {};
                } else {
                    this.errors.customValue = this.$t('form.validation.minMaxValues', {
                        min: 50,
                        max: 9999,
                    });
                }
            },
            async initialize() {
                this.loading = true;
                await this.getVouchers();
                this.loading = false;
            },
            async addProductToCart() {
                this.errors = {};
                if (!this.checkedIndex)
                    this.errors.checkedIndex = this.$t('form.validation.noOptionSelected');
                if (this.customValue < 50 || this.customValue > 9999)
                    this.errors.customValue = this.$t('form.validation.minMaxValues', {
                        min: 50,
                        max: 9999,
                    });
                if (Object.keys(this.errors).length !== 0) {
                    return;
                }
                this.sending = true;
                await this.addItem({
                    item: {
                        value: this.customValue,
                        type: 'voucher',
                        thumbnail: this.vouchers.image,
                        name: this.name,
                    },
                    isVoucher: true,
                });
                this.sending = false;

                if (Object.keys(this.inputErrors).length > 0) {
                    this.errors = Object.assign({}, inputErrors(this.inputErrors));
                    return;
                } else {
                    let message = this.$t('notification.productAddedToCartText');
                    let type = 'success';
                    let position = 'bottom-right';
                    if (!this.isMobileDevice) {
                        message = generateToastHtml(
                            'CART',
                            this.vouchers.image,
                            this.name,
                            this.customValue
                        );
                        type = 'product';
                        position = 'top-right';
                    }

                    this.$toast.open({
                        message: message,
                        duration: 4000,
                        position: position,
                        type: type,
                    });
                }
            },
        },
        created() {
            this.initialize();
        },
        watch: {
            lang: {
                handler() {
                    this.initialize();
                },
                deep: true,
            },
            customValue: {
                handler(value) {
                    this.formatedValue = value.length > 4 ? value.slice(0, 4) : value;
                },
            },
        },
        metaInfo() {
            return {
                title: this.vouchers['meta_title'],
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.vouchers['meta_description'],
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: this.vouchers['meta_keywords'],
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: this.vouchers['meta_title'],
                        template: (chunk) => `${chunk} | Pescario.ro`,
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: window.location.origin + this.$route.fullPath,
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: this.vouchers['meta_description'],
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: 'https://pescario.ro/logo.webp',
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: window.location.origin + this.$route.fullPath,
                    },
                ],
            };
        },
    };
</script>
<style scoped lang="scss">
.vouchers {
  .row {
    .cards {
      gap: 24px;
      @include media-breakpoint-down(md) {
        flex-direction: column;
        .predefined-cards {
          margin-top: 8px;
          order: 1;
        }
        .value-card {
          order: 0;
        }
      }
      .items {
        flex-wrap: wrap;
        .item {
          background-color: $white;
          width: calc(20% - 8px);
          display: flex;
          min-width: 120px;
          align-items: center;
          justify-content: center;
          position: relative;
          border-radius: 4px;
          height: 40px;
          border: 1px solid $gray-light;
          box-shadow: inset 4px 0px 0px $gray-light;
          transition: all 0.2s ease-in-out;
          @include media-breakpoint-down(lg) {
            width: calc(33% - 4px);
          }
          @include media-breakpoint-down(md) {
            width: calc(50% - 4px);
          }
          @include media-breakpoint-down(xm) {
            width: 100%;
            justify-content: left;
            padding-left: 16px;
          }
          label {
            cursor: pointer;
            .checkmark {
              background-color: $white;
              z-index: 1;
            }
            input[type='radio'] {
              &:checked ~ .checkmark {
                background-color: $primary;
              }
              &:focus ~ .checkmark {
                box-shadow: none;
              }
            }
            .text {
              font-size: 14px;
            }
          }
          &.selected {
            border: 1px solid $primary;
            box-shadow: inset 4px 0px 0px $primary;
            transition: all 0.2s ease-in-out;
            > p {
              color: $primary;
            }
          }
          @include media-breakpoint-up(md) {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .actions {
        p {
          font-size: 13px;
        }
        .custom-number {
          width: 150px;
          height: 40px;
          display: flex;
          justify-content: space-between;
          border: 1px solid $primary-25;
          border-radius: 4px;
          padding: 6px 4px 6px 12px;
          @include media-breakpoint-up(md) {
            &:focus-visible,
            &:hover {
              border: 1px solid $gray-dark !important;
              outline: none;
            }
          }
          input {
            flex: 1;
            color: $text;
            background: $backgroundGray;
            font-size: 14px;
            border: none;
            width: 100%;
            &:focus-visible,
            &:hover {
              border: none !important;
              outline: none !important;
            }
          }
          .arrows {
            display: flex;
            flex-direction: column;
            svg {
              cursor: pointer;
            }
          }
          &.error {
            background-color: $red-error;
            box-shadow: 0px 0px 0px 1px $red-light;
            border: none;
            @include media-breakpoint-up(md) {
              &:focus {
                box-shadow: 0px 0px 0px 2px rgba(194, 10, 34, 0.25);
              }
              &:focus-visible,
              &:hover {
                border: none !important;
                outline: none;
              }
            }
            input {
              background-color: rgba(230, 62, 67, 0);
              opacity: 1;
            }
          }
        }
      }
      .image {
        position: relative;
        filter: drop-shadow(0px 4px 6px rgb(202 217 226));
        border-radius: 16px;
        overflow: hidden;
        min-width: 300px;
        img {
          object-fit: cover;
          height: auto !important;
        }
        @include media-breakpoint-down(md) {
          max-width: 400px;
        }
      }
      .error-message {
        display: block;
        margin-top: 2px;
        font-size: 12px;
        color: $red;
      }
    }
  }
}
</style>
